<template>
  <div>
    <button class="btn" @click="fullScreen">
      {{isFullscreen?'退出全屏':'进入全屏'}}
    </button>
    <!-- <div class="line" v-else></div> -->
    <div class="games-container ">
      <audio ref="musicClick" src="@/assets/audio/game2/click.mp3"></audio>
      <audio
        ref="instruction"
        src="@/assets/audio/game3/instruction.mp3"
      ></audio>
      <div class="game-top">
        <div class="game-title"></div>
        <div class="game-instruction" @click="showMask"></div>
      </div>
      <!-- 砖块 begin -->
      <ul
        class="flex game-con"
        v-if="items.length > 0"
        :style="{
          background: 'url(' + preSrc + bg + ') center center no-repeat',
        }"
      >
        <li
          class="item"
          :class="[ item.half ? 'w-half' : '', item.act ? 'close' : 'open',]"
          v-for="(item, index) in items"
          :key="index"
          @click="hideItem(index)"
        >
          {{ item.id }}
        </li>
      </ul>
      <!-- 砖块 end -->
    </div>

    <!-- 弹窗begin -->
    <transition name="fade">
      <div
        class="mask-container"
        :class="[isShowMask ? 'mask-show' : 'mask-hide']"
        v-if="isShowMask"
      >
        <div class="mask-bg" @click="hideMask"></div>
        <div
          class="mask-con"
          :style="{
            background:
              'url(' + preSrc + instructionPic + ')center center no-repeat',
          }"
        >
        </div>
      </div>
    </transition>
    <!-- 弹窗end -->
  </div>
</template>

<script>
import screenfull from "screenfull";
import {getRandomArrayElements} from '@/tools/GlobalFunction';
export default {
  data() {
    return {
      preSrc: config.src,
      items: [],
      isShowMask: false,
      bg: "", //背景
      isFullscreen: false,
      instructionPic: "",
    };
  },
  created() {
    // 如果有configId就是用户创建的游戏
    let _self = this;
    // _self.setOriginPath(); //记录当前路由
    _self.initData();
    let configId = _self.$route.params.configId;
    let gameId = _self.$route.params.gameId;
    let title = _self.$route.params.title;
    title ? (document.title = title) : "";
    if (configId) {
      _self.getUserGameInfo(configId);
      return;
    } else {
      _self.getInfo(gameId);
    }
  },
  methods: {
    // 全屏
    fullScreen() {
      this.isFullscreen = !this.isFullscreen;
      // 如果不允许进入全屏，发出不允许提示
      if (!screenfull.isEnabled) {
        this.$message({
          message: "您的浏览器不支持全屏",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },

    initData() {
      let _self = this;
      for (let i = 1; i <= 95; i++) {
        let temp = {
          id: i,
          act: false,
          half: false,
        };        
        _self.items.push(temp);
      }
      let half = [10,19, 31, 42, 54, 65, 77, 86];
      let item2 = [23, 24, 25, 26, 27, 28];
      let item3 = [34, 35, 36, 37, 38, 39, 40];
      let item4 = [45, 46, 47, 48, 49, 50, 51];
      let item5 = [57, 58, 59, 60, 61, 62, 63];
      let item6 = [68, 69, 70, 71, 72, 73, 74];
      half.push(...getRandomArrayElements(item2, 4),
      ...getRandomArrayElements(item3, 4),
      ...getRandomArrayElements(item4, 4),
      ...getRandomArrayElements(item5, 4),
      ...getRandomArrayElements(item6, 4));
      _self.items.forEach(item => {
        half.includes(item.id) ? item.half = true: item.half = false
      })      
    },
    // 获取用户创建的游戏详情
    getUserGameInfo(id) {
      let _self = this;
      _self.axios
        .post(config.url.gamePics, _self.$qs.stringify({ config_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.instructionPic = res.data.instruction;
            if (res.data.config.length == 0) {
              return;
            }
            _self.bg = res.data.config[0];
          }
        });
    },
    // 获取游戏详情
    // getInfo(gameId) {
    //   let _self = this;
    //   _self.axios
    //     .post(config.url.gamePics, _self.$qs.stringify({ config_id:configId}))
    //     .then((res) => {
    //       if (res.code == 200) {
    //         _self.instructionPic = res.data.instruction;
    //         _self.bg = res.data.pic[0];
    //       } else if (res.code != 200 && res.code != 401) {
    //         _self.showTip("error", res.msg);
    //       }
    //     });
    // },
    getInfo(gameId) {
      let _self = this;
      _self.axios
        .post(config.url.gameInfo, _self.$qs.stringify({ game_id: gameId }))
        .then((res) => {
          if (res.code == 200) {
            _self.instructionPic = res.data.instruction;
            _self.bg = res.data.pic[0];
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    hideItem(index) {
      let _self = this;
      _self.$refs.musicClick.play();
      _self.items[index].act = !_self.items[index].act;
    },
    //展示弹窗
    showMask() {
      let _self = this;
      _self.$refs.instruction.play();
      _self.isShowMask = true;
    },
    hideMask() {
      let _self = this;
      _self.$refs.instruction.play();
      _self.isShowMask = false;
    },
  },
  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        this.isFullscreen = screenfull.isFullscreen;
      });
    }
  },
};
</script>
<style scoped>
.line {
  width: 100%;
  height: 60px;
}
.btn {
  margin: 60px auto 0;
}
.games-container {
  width: 1280px;
  height: 720px;
  margin: 30px auto 0;
  padding: 30px 15px;
  box-sizing: border-box;
  background: rgb(131, 172, 201);
  border: 6px solid #000;
}
.game-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}
.game-title {
  width: 510px;
  height: 57px;
  background: url("../../assets/img/game1/title.png") center center no-repeat;
  background-size: 100%;
  margin-right: 230px;
}
.game-instruction {
  width: 140px;
  height: 57px;
  margin-right: 25px;
  cursor: pointer;
}
.game-con {
  width: 1218px;
  max-width: 1218px;
  margin: 0 10px;
  background-size: contain !important;
  flex-wrap: wrap;
  border: 6px solid #000;
  box-sizing: border-box;
}
.item {
  width: 134px;
  /* width: 150px; */
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: rgb(224, 140, 123);
  box-sizing: border-box;
  border: 3px solid rgb(106, 56, 9);
  font-weight: bold;
  font-size: 22px;
  color: rgb(106, 56, 9);
  cursor: pointer;
}
.w-half {
  width: 67px;
}
.open{
  opacity: 1;
}
.close {
  cursor: pointer;
  /* visibility: hidden; */
  opacity: 0;
}
.mask-con {
  width: 738px;
  height: 542px;
  background-size: 100%;
  padding: 150px 80px 55px;
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.mask-con .title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 28px;
}
.mask-con p {
  font-size: 24px;
  line-height: 42px;
  text-align: left;
}
</style>
